import React, {useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import BGImg from "../../images/service/tab_image.jpg";
import {mediaBaseURL} from "../../utils/constant";


function Technologysec({data}) {
    const [activeTab, setActiveTab] = useState(0);

    function displayTab(tabNumber) {
        setActiveTab(tabNumber);
    }

    return (
        <>
            <div className="tekgriping_section p-100">
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={6} lg={5} xl={5} xxl={5} className="my-auto">
                            <div className="example" data-text={data?.app_cost_body_txt}>
                                <h2>{checkData(data, 'tekgrip_title')}</h2>
                            </div>
                            <p>{checkData(data, 'tekgrip_decription')}</p>
                        </Col>
                        <Col xs={12} md={6} lg={7} xl={7} xxl={7}>
                            <div className="tekgrip_flex">
                                <div className="tekgrip_title">
                                    <h4>{checkData(data, 'tekgrip_strategy_count')}
                                        <span> {checkData(data, 'tekgrip_strategy_content')}</span></h4>
                                </div>
                                <div className="tekgrip_title">
                                    <h4>{checkData(data, 'tekgrip_adapt_count')}
                                        <span> {checkData(data, 'tekgrip_adapt_description')}</span></h4>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={7} xl={7} xxl={7}>
                            {data?.tekgrip_development?.map((value, i) => (
                                <h3 key={i} onClick={() => {
                                    displayTab(i)
                                }} className={`tabs ${activeTab === i ? 'active' : ''}`}>
                                    <div className="tab_namee">{value.title}</div>
                                </h3>
                            ))}
                        </Col>

                    </Row>
                </Container>
            </div>
            <div className="tekgrip_tabs_iamge  p-100"
                 style={{backgroundImage: `url(${BGImg})`}}>
                <Container className="h-100">
                    {data?.tekgrip_development?.map((value, i) => (
                        <Row className={activeTab === i ? 'd-flex h-100' : 'd-none h-100'} key={i}>
                            <Col xs={12} md={7} lg={6} xl={6} xxl={6} className="my-auto">
                                <div className="tekgrip_tabs_content">
                                    <h2>{value.title}</h2>
                                    <p>{value.description}</p>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto">
                                <div className="tekgrip_tabs_image ">
                                    <img
                                        src={`${mediaBaseURL}${value.image01}`}
                                        className="img-fluid slide-top"
                                        alt="Tab_01"/>
                                    <img
                                        src={`${mediaBaseURL}${value.image02}`}
                                        className="img-fluid slide-top"
                                        alt="Tab_02"/>
                                </div>
                            </Col>
                        </Row>
                    ))}
                </Container>
            </div>
        </>
    );
}

export default Technologysec;
