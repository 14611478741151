import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

function Breadcrumbs(props) {
    let className = "breadcrumbs_style"
    if (props.bg) {
        className = "breadcrumbs_style white"
    }

    return (
        <section className={className}>
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12}>
                        <ul className="breadcrumb">
                            <li>{props.link1}</li>
                            <li>{props.link2}</li>
                            <li>{props.link3}</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Breadcrumbs;