import React from 'react';
import {Col, Container, Row, Accordion} from "react-bootstrap";

function Question({data}, props) {
    if (data && data.faqs) {
        return (
            <div className={props.color ? 'services_question bg_color p-100' : 'services_question p-100'}>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={8} xl={8} xxl={8} className="question_title">
                            <div className="example" data-text="FAQs">
                                <h2>Frequently Asked Questions</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="services_faqs">
                            <Accordion defaultActiveKey="0">
                                {data?.faqs?.map((value, i) => (
                                    <Accordion.Item eventKey={i} key={i}>
                                        <Accordion.Header>{value.question}</Accordion.Header>
                                        <Accordion.Body>
                                            <div dangerouslySetInnerHTML={{__html: value.answer}}/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </div>

        );
    } else {
        return (
            <></>
        );
    }
}

export default Question;

