import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import HtmlParser from 'react-html-parser';

function Banner({data}) {
    return (
        <div className="services_banner">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto">
                        <div className="banenr_content">
                            <div className="example" data-text={data?.banner_body_txt}>
                                <h1>{HtmlParser(checkData(data, 'banner_title'))}</h1>
                            </div>
                            <p className="main_para">{HtmlParser(checkData(data, 'subtitle'))}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto">
                        <div className="banner_content_txt">
                            <div>{HtmlParser(checkData(data, 'banner_text'))}</div>
                            <ul>
                                {data?.banner_list?.map((item, i) => (
                                    <li key={i}>{checkData(item, 'banner_list')}</li>
                                ))}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}


export default Banner;