import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import HtmlParser from 'react-html-parser';

function Agile({data}) {
    return (
        <div className="tekrevol_gray_bg p-100">
            <Container classNanme="h-100">
                <Row classNanme="h-100">
                    <Col xs={12} md={6} lg={6} xl={5} xxl={5}>
                        <div className="example" data-text={data?.service_agile_body_txt}>
                            <h2>{checkData(data, 'service_agile_title')}</h2>
                        </div>
                        <h5>{HtmlParser(checkData(data, 'service_agile_sub_title'))}</h5>
                        <p>{HtmlParser(checkData(data, 'service_agile_content'))}</p>

                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="agile_title my-auto offset-xl-1">
                        <div className="content_pointer">
                            {data?.service_agile_feature?.map((value, i) => (
                                <div className="pointers_item" key={i}>
                                    <div className="title_count">{value.count} <span>{value.description}</span></div>
                                </div>
                            ))}
                        </div>
                        <img
                            src={`${checkData(data, 'service_agile_image')}`}
                            className="img-fluid"
                            alt={checkData(data, 'service_agile_title')}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Agile;