import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Link from "gatsby-link";
import {checkData} from "../../helper";
import Img_01 from "../../images/service/image_01.png";
import Img_02 from "../../images/service/image_02.png";
import Img_03 from "../../images/service/image_03.png";
import Img_04 from "../../images/service/image_04.png";
import HtmlParser from 'react-html-parser';
import {mediaBaseURL} from "../../utils/constant";


function Servicespartner({data}) {
    return (
        <div className="tekrevol_gray_bg p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={5} xl={5} xxl={5} className="my-auto">
                        <div className="example" data-text={data?.service_body_txt}>
                            <h2>{checkData(data, 'service_partner_title')}</h2>
                        </div>
                        <h5 className="strong">{checkData(data, 'service_partner_subtitle')}</h5>
                        <div>{HtmlParser(checkData(data, 'service_partner_content'))}</div>
                        <Link to={checkData(data, 'service_partner_btn_url')}
                              className="border_btn">{checkData(data, 'service_partner_btn_txt')}</Link>
                    </Col>
                    {data?.service_partner_tabs ?
                        <>
                            <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="mt-lg-5 offset-lg-1">
                                <Row>

                                    {data?.service_partner_tabs?.map((value, i) => {
                                        if ((i + 1) % 2 === 0) {
                                            return (
                                                <Col xs={12} md={6} lg={6} xl={6}
                                                     xxl={6}>
                                                    <div className="set_of_images hover01" key={i}>
                                                        <figure>
                                                            <img
                                                                src={`${mediaBaseURL}${value.image}`}
                                                                className="img-fluid"
                                                                alt="image"/>
                                                        </figure>
                                                        <p className="img_title">{value.service_partner_tabs}</p>
                                                    </div>
                                                </Col>
                                            );
                                        } else {
                                            return (
                                                <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="mt-lg-4 mt-md-0">
                                                    <div className="set_of_images hover01" key={i}>
                                                        <figure>
                                                            <img
                                                                src={`${mediaBaseURL}${value.image}`}
                                                                className="img-fluid"
                                                                alt="image"/>
                                                        </figure>
                                                        <p className="img_title">{value.service_partner_tabs}</p>
                                                    </div>
                                                </Col>
                                            );
                                        }
                                    })}
                                </Row>
                            </Col>
                        </>

                        :
                        <>
                            <Col xs={12} md={3} lg={3} xl={3} xxl={3} className="mt-lg-5 offset-lg-1 mt-3 mt-md-0">
                                <div className="set_of_images hover01">
                                    <figure>
                                        <img
                                            src={Img_01}
                                            className="img-fluid"
                                            alt="image"/>
                                    </figure>
                                    <p className="img_title">Automotive Industry</p>
                                </div>
                                <div className="set_of_images hover01">
                                    <figure>
                                        <img
                                            src={Img_02}
                                            className="img-fluid"
                                            alt="image"/>
                                    </figure>
                                    <p className="img_title">Streaming Industry</p>
                                </div>
                            </Col>
                            <Col xs={12} md={3} lg={3} xl={3} xxl={3}>
                                <div className="set_of_images hover01">
                                    <figure>
                                        <img
                                            src={Img_03}
                                            className="img-fluid"
                                            alt="image"/>
                                    </figure>
                                    <p className="img_title">AI-Voice Based Assistants</p>
                                </div>
                                <div className="set_of_images hover01">
                                    <figure>
                                        <img
                                            src={Img_04}
                                            className="img-fluid"
                                            alt="image"/>
                                    </figure>
                                    <p className="img_title">Reviews & Ratings Industry</p>
                                </div>
                            </Col>
                        </>
                    }

                </Row>
            </Container>
        </div>
    );
}

export default Servicespartner;