import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Link from "gatsby-link";
import {checkData} from "../../helper";
import HtmlParser from 'react-html-parser';

function Productlifecycle({data}) {
    return (
        <div>
            <Container className="h-100 tekrevol_dark_bg p-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text={data?.lifecycle_body_txt}>
                            <h2>{checkData(data, 'lifecycle_title')}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={6} xxl={6}>
                        <div>{HtmlParser(checkData(data, 'lifecycle_content'))}</div>
                        <Link to={checkData(data, 'lifecycle_btn_url')}
                              className="border_btn">{checkData(data, 'lifecycle_btn_text')}</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Productlifecycle;