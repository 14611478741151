import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import HtmlParser from 'react-html-parser';

function Buildpurpose({data}) {
    return (
        <div className="services_orange_bg p-100">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto text-center order-2 order-md-1">
                        <img
                            src={data?.service_banner_image}
                            className="img-fluid"
                            alt={data?.service__banner_title}/>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="services_title my-auto order-1 order-md-2">
                        <h2>{data?.service__banner_title}</h2>
                        <p>{HtmlParser(data?.service_banner_description)}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Buildpurpose;