import React, {useEffect, useState} from 'react';
import Banner from "./banner";
import Buildpurpose from "./buildpurpose";
import Appbuild from "./appbuild";
import Appcost from "./appcost";
import Productlifecycle from "./productlifecycle";
import Solution from "./solution";
import Servicespartner from "./servicespartner";
import Featured from "../home/featured";
import Partner from "../home/partner";
import Technologysec from "./technologysec";
import Industries from "./Industries";
import Agile from "./agile";
import Question from "./question";
import Layout from "../layout/layout";
import Seo from "../layout/seo";
import {checkData} from "../../helper";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import Breadcrumbs from "../components/breadcrumbs";
import Loader from "../components/Loader";
import Developmentprocess from "./developmentprocess";


function Index({slug, bg}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true)
        const response = await axios.get(`${baseURL}/page/${slug}`);
        setUserData(response.data.data);
        setIsLoaded(false)
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, [slug]);


    return (

        <Layout slug={slug}>

            {isLoaded ?
                <Loader/>
                :
                <>
                    <Seo title={checkData(userData, 'meta_title')}
                         description={checkData(userData, 'meta_description')}/>
                    <Breadcrumbs link1="TekRevol" link2="Services" link3={userData.name}/>
                    <Banner data={userData}/>
                    <Buildpurpose data={userData}/>
                    <Appbuild data={userData}/>
                    <Technologysec data={userData}/>
                    <Appcost data={userData}/>
                    <Productlifecycle data={userData}/>
                    <Solution data={userData}/>
                    <Servicespartner data={userData}/>
                    <Industries data={userData}/>
                    <Agile data={userData}/>
                    <Developmentprocess data={userData}/>
                    <Question data={userData}/>
                    <Featured/>
                    <Partner/>
                </>
            }

        </Layout>
    );
}

export default Index;