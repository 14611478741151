import React from 'react';
import {Col, Container, Row, Tabs, Tab, Accordion} from "react-bootstrap";
import Link from 'gatsby-link';
import {checkData} from "../../helper";
import HtmlParser from 'react-html-parser';


function Appbuild({data}) {
    return (
        <div className="services_buildapp p-100">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text={data?.case_study_body_txt}>
                            <h2>{HtmlParser(checkData(data, 'case_study_title'))}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <p>{HtmlParser(checkData(data, 'case_study_description'))}</p>
                    </Col>
                </Row>
                <Row className="services_bg ">
                    <Col xs={12} md={12} lg={8} xl={8} xxl={8} className="services_tabs deskstop_version">
                        <Tabs defaultActiveKey="0" transition={false}>
                            {data?.case_study?.map((value, i) => (
                                <Tab eventKey={i} title={value.tab_name} key={i}>
                                    <div className="content_tabs">
                                        <h2>{value.project_name}</h2>
                                        <p>{value.project_description}</p>
                                        <Link to={value.case_study_url}>See case study</Link>
                                    </div>
                                </Tab>
                            ))}

                        </Tabs>
                    </Col>
                    <Col xs={12} md={12} lg={8} xl={8} xxl={8} className="services_tabs mobile_version">
                        <div className="tekrevol_mobile_accordion">
                            <Accordion
                                defaultActiveKey={data?.case_study?.length > 0 ? 0 : '0'}>
                                {data?.case_study?.map((item, i) => (
                                    <Accordion.Item eventKey={i}>
                                        <Accordion.Header>{item.project_name}</Accordion.Header>
                                        <Accordion.Body>
                                            <p>{HtmlParser(checkData(item, 'project_description'))}</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={4} xl={4} xxl={4} className="services_grade_tabs">
                        <Tabs defaultActiveKey="0">
                            {data?.packages?.map((value, i) => (
                                <Tab eventKey={i} title={value.package_name}>
                                    <div className="tab_hour">
                                        <h5>{value.hours}<span>{value.hours_content}</span></h5>
                                        <h5>{value.months}<span>{value.month_content}</span></h5>
                                    </div>
                                    <ul className="feature_name">
                                        {value?.package_features?.map((item, i) => (
                                            <li key={i}>{item.name}</li>
                                        ))}
                                    </ul>
                                </Tab>
                            ))}
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </div>
    )
        ;
}

export default Appbuild;
