import React, {useState} from 'react';
import {Col, Container, Row, Accordion} from "react-bootstrap";
import {checkData} from "../../helper";
import HtmlParser from 'react-html-parser';
import {mediaBaseURL} from "../../utils/constant";

function Industries({data}) {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    const getActiveClass = (index, className) =>
        toggleState === index ? className : "";

    return (
        <div className="seriveces_industries p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={7} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text={data?.industries_body_txt}>
                            <h2>{checkData(data, 'industry_name')}</h2>
                        </div>
                        <p className="strong">{HtmlParser(checkData(data, 'industry_description'))}</p>
                    </Col>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="deskstop_version">
                        <div className="process_icons">
                            <ul>
                                {data?.industries?.map((value, i) => (
                                    <li key={i}
                                        className={`tabs ${getActiveClass(i, "active-tabs")}`}
                                        onClick={() => toggleTab(i)}
                                    >
                                        <div className="iconss">
                                            <img
                                                src={`${mediaBaseURL}${value.image}`}
                                                className="img-fluid"
                                                alt={checkData(value, 'title')}/>
                                        </div>
                                        {value.title}

                                    </li>
                                ))}
                            </ul>
                            {data?.industries?.map((value, i) => (
                                <div key={i}
                                     className={toggleState === i ? 'content d-block' : "content d-none"}>
                                    <p>{value.description}</p>
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="mobile_version">
                        <div className="tekrevol_mobile_accordion_side">
                            <Accordion
                                defaultActiveKey={data?.industries?.length > 0 ? 0 : '0'}>
                                {data?.industries?.map((item, i) => (
                                    <Accordion.Item eventKey={i}>
                                        <Accordion.Header>
                                            <img
                                                src={`${mediaBaseURL}${item.image}`}
                                                className="img-fluid"
                                                alt={checkData(item, 'title')}/>
                                            {item.title}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {HtmlParser(checkData(item, 'description'))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Industries;
